import React from 'react';
import { PageProps, graphql, Link } from 'gatsby';
import GlobalStyle from '@/styles/GlobalStyles';


const Home = () => {
  return <main>
    <GlobalStyle />
  </main>

}
export default Home;
